import React, { useState } from 'react';
import './SizeChart.css';

const SizeChart = () => {
  // Arrays of size chart images
  const jerseySizeImages = [
    '/s (1).jpg',
    '/s (1).jpg',

  ];

  const tShirtSizeImages = [
    '/s (2).jpg',
    '/s (3).jpg',
  
  ];

  const [popupImage, setPopupImage] = useState(null);

  const openPopup = (image) => {
    setPopupImage(image);
  };

  const closePopup = () => {
    setPopupImage(null);
  };

  return (
    <div className="size-chart-container">
      <div className="size-chart-section">
        <p className="size-chart-subtitle">Jersey Sizes</p>
        <h1 className="size-chart-title">Explore Our Jersey Size Options</h1>
        <div className="size-chart-grid">
          {jerseySizeImages.map((image, index) => (
            <div key={index} className="size-chart-item" onClick={() => openPopup(image)}>
              <img src={image} alt={`Jersey Size ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="size-chart-section">
        <p className="size-chart-subtitle">Crocodile T-Shirt Sizes</p>
        <h1 className="size-chart-title">Perfect Fit for Every Occasion</h1>
        <div className="size-chart-grid">
          {tShirtSizeImages.map((image, index) => (
            <div key={index} className="size-chart-item" onClick={() => openPopup(image)}>
              <img src={image} alt={`T-Shirt Size ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Popup for Image View */}
      {popupImage && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content">
            <img src={popupImage} alt="Popup" />
            <button className="close-button" onClick={closePopup}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SizeChart;

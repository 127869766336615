import React from 'react';
import './Welcome.css';

const Card = () => {
  return (
    <div className="welcome-page-container">
      {/* Explore Section */}
      <section className="welcome-explore-section">
        <h3 className="welcome-explore-heading">Explore # What You Can Get Here</h3>
      </section>

      {/* Featured Service Section */}
      <section className="welcome-feature-section">
        <div className="welcome-feature-text">
          <small className="welcome-feature-subtitle">Best quality with top designs</small>
          <h4 className="welcome-feature-title">High quality sublimation printed T-shirts</h4>
          <p className="welcome-feature-description">
            Elevate your team's style with Craft Clothings T-Shirts. Our sublimation printed t-shirts are perfect for sports clubs, schools, and cricket teams. With vibrant designs and superior quality, these t-shirts make a statement both on and off the field. Each t-shirt is meticulously crafted using cutting-edge sublimation printing techniques, ensuring long-lasting color and comfort. Unleash your team’s spirit with Craft Clothings T-Shirts, the ultimate choice for sports enthusiasts.
          </p>
        </div>
        <div className="welcome-feature-image-container">
          <img className="welcome-feature-image" src="/image (10).jpg" alt="High quality sublimation printed T-shirts" />
        </div>
      </section>
      <section className="welcome-feature-section">
        <div className="welcome-feature-text">
          <small className="welcome-feature-subtitle">Best quality with top designs</small>
          <h4 className="welcome-feature-title">Best Corporate T-shirts</h4>
          <p className="welcome-feature-description">
          Transform your brand image with Kandurata T-Shirts’ corporate tees. Customized for schools and businesses, our innovative and reliable t-shirt printing service in Sri Lanka ensures top quality. Stand out with custom logo decorating methods. Trust us for uniform t-shirts that make brand evangelists. Enjoy island-wide delivery.
          </p>
        </div>
        <div className="welcome-feature-image-container">
          <img className="welcome-feature-image" src="/image.jpg" alt="High quality sublimation printed T-shirts"  />
        </div>
      </section>
    </div>
  );
};

export default Card;

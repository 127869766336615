import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram, FaPinterest, FaYoutube, FaEnvelope, FaWhatsapp, FaTshirt, FaGlobe } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Logo and Description */}
        <div className="footer-logo-section">
          <img src="/CC new.png" alt="Craft Clothing Logo" className="footer-logo" />
          <p className="footer-description">
            The ultimate destination for custom printed t-shirts in Sri Lanka. We are committed to delivering top-quality products.
          </p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-links-section">
          <h4 className="footer-heading">QUICK LINKS</h4>
          <ul>
            <li><FaGlobe className="icon" /> Home</li>
            <li><FaTshirt className="icon" /> Corporate</li>
            <li><FaTshirt className="icon" /> Sublimation</li>
          </ul>
        </div>

        {/* Contact Information Section */}
        <div className="footer-contact-section">
          <h4 className="footer-heading">CONTACT US</h4>
          <p><span role="img" aria-label="location" className="icon">📍</span> Main Street, Nuraicholai, Puttalam.</p>
          <p><FaEnvelope className="icon" /> hazny.mhd@gmail.com</p>
          <p><FaWhatsapp className="icon" /> +94 71 777 7668</p>
        </div>

        {/* Social Media Icons Section */}
        <div className="footer-social-section">
          <h4 className="footer-heading">FOLLOW US..</h4>
          <p>We are available on..</p>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer"><FaPinterest /></a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
            <a href="mailto:hazny.mhd@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope /></a>
            <a href="https://wa.me/94717777668" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

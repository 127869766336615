import React from 'react';
import './Gallery.css';

const Gallery = () => {
  // Array of images
  const images = [
    '/a (1).jpg',
    '/a (2).jpg',
    '/a (3).jpg',
    '/a (4).jpg',
    '/a (5).jpg',
    '/a (6).jpg',
    '/a (7).jpg',
    '/a (8).jpg',
    '/a (9).jpg',
    '/a (10).jpg',
    '/a (11).jpg',
    '/a (12).jpg',
    '/a (13).jpg',
  ];

  return (
    <div className="gallery-container">
      <div className="gallery-header">
        <p className="gallery-subtitle">Gallery</p>
        <h1 className="gallery-title">All Designs</h1>
        <p className="gallery-hashtag">we make your Design 100% same</p>
      </div>

      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image} alt={`Gallery ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Home from './components/Home';
import Highlights from './components/Highlights';
import ServicePage from './components/Service';
import Welcome from './components/Welcome';
import Card from './components/card';
import GetInTouch from './components/GetInTouch';
import Footer from './components/Footer';
import './App.css';
import Gallery from './components/Gallery';
import Jersey from './components/Jersey';
import TShirt from './components/TShirt';
import Design from './components/Design';
import SizeChart from './components/SizeChart';

function App() {
  return (
    <Router>
      <div className="container">
        <Nav />
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />

          {/* Other Routes */}
          <Route path="/highlights" element={<Highlights />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/card" element={<Card />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/getintouch" element={<GetInTouch />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Jersey" element={<Jersey />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/TShirt" element={<TShirt />} />
          <Route path="SizeChart" element={<SizeChart />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

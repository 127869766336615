import React, { useState, useEffect, useRef } from 'react';
import './ServicePage.css';

const ServicePage = () => {
  const images = [
    '/service banner 1.jpg',
    '/service banner 2.jpg',
    '/service banner 3.jpg',
    '/service banner 4.jpg',
    '/service banner 5.jpg',
    '/service banner 6.jpg',
    '/service banner 7.jpg',
    '/service banner 8.jpg',
  ];

  const imagesPerRowDesktop = 4; // Display 4 images per row in desktop view
  const imagesPerRowMobile = 8;  // Display 2 images per row in mobile view (corrected)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const carouselContainerRef = useRef(null);

  // Detect mobile or desktop view based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate the total number of slides (each dot represents 1 image in mobile view)
  const totalSlides = images.length;

  // Smooth scrolling when changing slides in mobile view
  useEffect(() => {
    if (isMobileView && carouselContainerRef.current) {
      const container = carouselContainerRef.current;
      container.scrollTo({
        left: currentIndex * (container.clientWidth / imagesPerRowMobile),
        behavior: 'smooth',
      });
    }
  }, [currentIndex, isMobileView]);

  // Move to the next image
  const nextSlide = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  // Move to the previous image
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  // Get the visible images based on the currentIndex for desktop and mobile
  const visibleImages = isMobileView
    ? images.slice(currentIndex, currentIndex + imagesPerRowMobile)
    : images.slice(currentIndex, currentIndex + imagesPerRowDesktop);

  return (
    <div className="service-page">
      <h1 className="service-title">SERVICE</h1>
      <div className="carousel">
        {/* Desktop View: Previous Button */}
        {!isMobileView && (
          <button
            className="prev-arrow"
            onClick={prevSlide}
            disabled={currentIndex === 0}
          >
            ❮
          </button>
        )}

        <div className="carousel-container" ref={carouselContainerRef}>
          <div className="carousel-row">
            {visibleImages.map((image, index) => (
              <div key={index} className="carousel-item">
                <img className="service-image" src={image} alt={`Service ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* Desktop View: Next Button */}
        {!isMobileView && (
          <button
            className="next-arrow"
            onClick={nextSlide}
            disabled={currentIndex >= totalSlides - imagesPerRowDesktop}
          >
            ❯
          </button>
        )}

        {/* Mobile View: Dot Indicators */}
        {isMobileView && (
          <div className="dots-container">
            {Array.from({ length: totalSlides }).map((_, index) => (
              <span
                key={index}
                className={`dot ${currentIndex === index ? 'active-dot' : ''}`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicePage;

import React from 'react';
import './TShirt.css';

const TShirt = () => {
  const images = [
    '/t (1).jpeg', 't (1).jpg', '/t (1).png', '/t (2).jpeg', '/t (2).jpg',
    '/t (3).jpeg', '/t (3).jpg', 
  ];

  return (
    <div className="tshirt-container">
      <div className="tshirt-header">
        <p className="tshirt-subtitle">T-Shirt</p>
        <h1 className="tshirt-title">Our Best T-Shirts</h1>
        <p className="tshirt-hashtag">Made to perfection!</p>
      </div>

      <div className="tshirt-grid">
        {images.map((image, index) => (
          <div key={index} className="tshirt-item">
            <img src={image} alt={`T-Shirt ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TShirt;

// src/components/Highlights.jsx
import React from 'react';
import './Highlights.css';

const Highlights = () => {
  return (
    <div className="highlights-section">
      <h2 className="highlights-title">This Week’s Highlights</h2>
      <div className="highlight-categories">
        <span>New Designs</span>
        <span>Jersey</span>
        <span>Shorts</span>
        <span>T-Shirts</span>
        <span>Arm Cuts</span>
        <span>Bottoms</span>
      </div>
      <div className="highlights-grid">
        {/* Small Images */}
        <div className="highlight-item small image1">
          <div className="image-container">
            <img src="/image.jpg" alt="1" />
          </div>
        </div>
        <div className="highlight-item small image2">
          <div className="image-container">
            <img src="/image (13).jpg" alt="2" />
          </div>
        </div>

        {/* Large Images */}
        <div className="highlight-item large image3">
          <div className="image-container">
            <img src="/image (2).jpg" alt="3" />
          </div>
        </div>
        <div className="highlight-item large image4">
          <div className="image-container">
            <img src="/image (11).jpg" alt="4" />
          </div>
        </div>

        {/* More Small Images */}
        <div className="highlight-item small image5">
          <div className="image-container">
            <img src="/image (14).jpg" alt="5" />
          </div>
        </div>
        <div className="highlight-item small image6">
          <div className="image-container">
            <img src="/image (1).jpg" alt="6" />
          </div>
        </div>

        {/* More Large Images */}
        <div className="highlight-item large image7">
          <div className="image-container">
            <img src="/image (12).jpg" alt="7" />
          </div>
        </div>
        <div className="highlight-item large image8">
          <div className="image-container">
            <img src="/image (3).jpg" alt="8" />
          </div>
        </div>
      </div>
      <h1 className="Note">New Jersey T-Shirt & etc... Designs</h1>
      <p className="Note1">Custom Jerseys | Your Design | Name | Team Name | Logo | Number </p>
    </div>
  );
};

export default Highlights;

import React from 'react';
import './Welcome.css';

const Welcome = () => {
  return (
    <div className="welcome-page-container">
      {/* Main Heading Section */}
      <section className="welcome-intro-section">
        <h1 className="welcome-main-heading">Welcome to</h1>
        <h2 className="welcome-subheading">Craft Clothing Jersey Printing & T Shirts</h2>
        <p className="welcome-description">
        Craftclothings T-Shirts: Sri Lanka's go-to for premium custom printed t-shirts. We specialize in high-quality embroidery and sublimation printing for personalized designs, logos, and vibrant photos. Ideal for sports clubs, schools, teams, and corporate events, we offer reliable island-wide delivery to ensure team unity and brand recognition.        </p>
      </section>
    </div>
  );
};

export default Welcome;

import React from 'react';
import './Jersey.css';

const Jersey = () => {
  const images = [
    '/j (1).jpg', '/j (2).jpg', '/j (3).jpg', '/j (4).jpg', '/j (5).jpg',
    '/j (6).jpg', '/j (7).jpg', '/j (8).jpg', '/j (9).jpg', '/j (10).jpg'
  ];

  return (
    <div className="jersey-container">
      <div className="jersey-header">
        <p className="jersey-subtitle">Jersey</p>
        <h1 className="jersey-title">Our Exclusive Jerseys</h1>
        <p className="jersey-hashtag">Designed for champions!</p>
      </div>

      <div className="jersey-grid">
        {images.map((image, index) => (
          <div key={index} className="jersey-item">
            <img src={image} alt={`Jersey ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Jersey;

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import Welcome from './Welcome';
import Highlights from './Highlights';
import Card from './card';
import ServicePage from './Service';
import GetInTouch from './GetInTouch';


const Home = () => {
  const images = [
    '/2024_09_17_17_46_IMG_0130.GIF', 
    '/web banner.jpg', 
    '/web banner1.jpg',
    '/2024_09_17_18_00_IMG_0132.GIF',
    '/web banner4.jpg',
    '/web banner3.jpg',
  ];

  const mobileImages = [
    '/2024_09_17_17_46_IMG_0130_mobile.gif',
    '/web banner_mobile.jpg',
    '/web banner1_mobile.jpg',
    '/2024_09_17_18_00_IMG_0132_mobile.gif',
    '/web banner4_mobile.jpg',
    '/web banner3_mobile.jpg',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Detect screen resize for mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Automatically move to the next slide every 5.5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 500);
    }, 5300);

    return () => clearInterval(interval);
  }, [images.length]);

  const prevSlide = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      setFade(true);
    }, 500);
  };

  const nextSlide = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFade(true);
    }, 500);
  };

  const displayedImages = isMobile ? mobileImages : images;
  const isGif = displayedImages[currentIndex].toLowerCase().endsWith('.gif');

  return (
    <>
      <Helmet>
        <title>T-Shirt - Custom Printed T-Shirts in Sri Lanka</title>
        <meta name="description" content="Discover high-quality custom printed t-shirts for corporate events, sports teams, and personal use at Craft Clothing in Sri Lanka." />
        <meta name="keywords" content="custom t-shirts, Sri Lanka, corporate t-shirts, sports team shirts, sublimation printing" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Craft Clothing - Custom Printed T-Shirts in Sri Lanka" />
        <meta property="og:description" content="High-quality custom printed t-shirts tailored for all occasions in Sri Lanka." />
        <meta property="og:image" content="https://www.craftclothings.com/og-image.jpg" />
        <meta property="og:url" content="https://www.craftclothings.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Craft Clothing - Custom Printed T-Shirts in Sri Lanka" />
        <meta name="twitter:description" content="High-quality custom printed t-shirts available for all events in Sri Lanka." />
        <meta name="twitter:image" content="https://www.craftclothings.com/twitter-image.jpg" />
      </Helmet>
      <div className="home">
        <div className="slider">
          <img
            src={displayedImages[currentIndex]}
            alt="Banner"
            className={`slider-image ${fade ? 'fade-in' : ''}`}
            style={{ height: isGif ? (isMobile ? '585px' : '680px') : 'auto' }}
          />
          <button className="prev" onClick={prevSlide}>❮</button>
          <button className="next" onClick={nextSlide}>❯</button>
        </div>

        {/* Slider Indicators */}
        <div className="slider-indicators">
          {displayedImages.map((_, index) => (
            <div className="indicator__label-line" key={index}>
              <span
                className="indicator__label-line-filled"
                style={{
                  width: currentIndex === index ? '100%' : '0%',
                  transition: currentIndex === index ? 'width 5.5s ease-in-out' : 'none',
                }}
              ></span>
            </div>
          ))}
        </div>
      </div>
      <Welcome />
      <Highlights />
      <Card />
      <ServicePage />
      <GetInTouch />
    </>

  );
};

export default Home;

import React from 'react';
import './GetInTouch.css';

const GetInTouch = () => {
  return (
    <div className="get-in-touch-container">
      <div className="get-in-touch-content">
        <h4 className="get-in-touch-subheading">Say Hello! We Love It!</h4>
        <h2 className="get-in-touch-heading">GET IN TOUCH.</h2>
        <p className="get-in-touch-description">We are waiting for a message from you!</p>
        
        <div className="contact-details">
          <div className="contact-item">
            <span role="img" aria-label="location">📍</span>
            <p>Main Street, Nuraicholai, Puttalam.</p>
          </div>
          <div className="contact-item">
            <span role="img" aria-label="email">✉️</span>
            <p>hazny.mhd@gmail.com</p>
          </div>
          <div className="contact-item">
            <span role="img" aria-label="phone">📞</span>
            <p>+94 71 777 7668</p>
          </div>
        </div>
        
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="FacebookColor.png" alt="Facebook" />
          </a>
          <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer">
            <img src="/WhatsappColor.png" alt="WhatsApp" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Use NavLink for active link highlighting
import './Nav.css'; // Ensure your CSS file is linked correctly

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      {/* Logo */}
      <div className="navbar-logo">
        <img src="/cccs.png" alt="CraftClothings" />
      </div>

      {/* Desktop Links */}
      <ul className="navbar-links">
        <li>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/design" className={({ isActive }) => (isActive ? 'active' : '')}>
            Design
          </NavLink>
        </li>
        <li>
          <NavLink to="/jersey" className={({ isActive }) => (isActive ? 'active' : '')}>
            Jersey
          </NavLink>
        </li>
        <li>
          <NavLink to="/tshirt" className={({ isActive }) => (isActive ? 'active' : '')}>
            T-Shirt
          </NavLink>
        </li>
        <li>
          <NavLink to="/gallery" className={({ isActive }) => (isActive ? 'active' : '')}>
            Gallery
          </NavLink>
        </li>
        <li>
          <NavLink to="/getintouch" className={({ isActive }) => (isActive ? 'active' : '')}>
            Contact
          </NavLink>
        </li>
        <li>
        <NavLink to="/SizeChart" className={({ isActive }) => (isActive ? 'active' : '')}>
            Size Chart
          </NavLink>
        </li>
      </ul>

      {/* Right side icons */}
      <div className="right-side">
        <ul className="right-links">
          <li>
            <a href="#support">Support</a>
          </li>
          <li>
            <a href="#forbusiness">For Business</a>
          </li>
        </ul>
        <div className="navbar-icons">
          <span className="search-icon">
            <img src="/search.png" alt="Search" />
          </span>
          <span className="cart-icon">
            <img src="/cart.png" alt="Cart" />
          </span>
          <span className="user-icon">
            <img src="/user.png" alt="User" />
          </span>

          {/* Mobile menu icon */}
          <span className="menu-icon" onClick={toggleMenu}>
            <img src="/menu-icon.png" alt="Menu" />
          </span>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="mobile-menu">
          <ul>
            <li>
              <NavLink to="/" onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/design" onClick={closeMenu}>
                Design
              </NavLink>
            </li>
            <li>
              <NavLink to="/jersey" onClick={closeMenu}>
                Jersey
              </NavLink>
            </li>
            <li>
              <NavLink to="/tshirt" onClick={closeMenu}>
                T-Shirt
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery" onClick={closeMenu}>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink to="/getintouch" onClick={closeMenu}>
                Contact
              </NavLink>
            </li>
            <li>
              <a href="/SizeChart" onClick={closeMenu}>
                Size & Color
              </a>
            </li>
            <li>
              <a href="#support" onClick={closeMenu}>
                Support
              </a>
            </li>
            <li>
              <a href="#forbusiness" onClick={closeMenu}>
                For Business
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Nav;
